import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TopNavPortal from '../../../components/layout/top-menu/TopNavPortal';
import DataRow from '../../../components/shared/data-grid/DataRow';
import SortableHeading from '../../../components/shared/data-grid/SortableHeading';
import Button, { ButtonType, ButtonSize } from '../../../components/shared/form-control/Button';
import DropdownSelect from '../../../components/shared/form-control/DropdownSelect';
import { Input } from '../../../components/shared/form-control/Input';
import { SearchInput } from '../../../components/shared/form-control/SearchInput';
import Loader from '../../../components/shared/Loader';
import ConfirmationModal from '../../../components/shared/modal/variants/ConfirmationModal';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import useDebounce from '../../../hooks/useDebounce';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { AccountType } from '../../../models/Account';
import AdminService from '../../../services/AdminService';
import ClientService from '../../../services/ClientService';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import { useProcessesHub } from '../../../contexts/signalR/ProcessesContext';
import { Client } from '../../../models/Client';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../../../components/shared/modal/variants/StandardModal';
import { systemDefaultLanguageCode } from '../../../types/Languages';
import Building2Icon from '../../../components/shared/icon/Building2Icon';

const Organisations: FC = () => {
  const { t } = useTranslation(['accounts', 'common']);
  const [loading, setLoading] = useState(true);
  const [newTenantModalOpen, setNewTenantModalOpen] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState<Client | null>(null);
  const [newAccountName, setNewAccountName] = useState('');
  const [newAccountType, setNewAccountType] = useState(AccountType.Organisation);
  const accountTypeOptions = useMemo(
    () => [
      { id: AccountType.Organisation, text: t(`accounts:account-types.${AccountType.Organisation}`), value: AccountType.Organisation },
      { id: AccountType.PlaybookAdmin, text: t(`accounts:account-types.${AccountType.PlaybookAdmin}`), value: AccountType.PlaybookAdmin },
      { id: AccountType.ServiceProvider, text: t(`accounts:account-types.${AccountType.ServiceProvider}`), value: AccountType.ServiceProvider },
    ],
    [t],
  );
  const [isValid, setIsValid] = useState(false);
  const selectedAccountType = useMemo(() => accountTypeOptions.find((x) => x.id === newAccountType), [accountTypeOptions, newAccountType]);
  const [accounts, setAccounts] = useState<Client[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery, 700);
  const [sortBy, setSortBy] = useState('+name');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const navigate = useNavigate();
  const toasts = useToasts();
  const toastsRef = useRef(toasts);

  const { listenToProcess } = useProcessesHub();

  const fetchAccounts = useCallback(() => {
    setLoading(true);
    AdminService.getAccounts({ searchQuery: debouncedSearch, sortBy, pageNumber: pageNumber }).then((res) => {
      setAccounts((prev) => [...(pageNumber > 1 ? prev : []), ...res.data]);
      setHasNextPage(res.hasNextPage || false);
      setLoading(false);
    });
  }, [debouncedSearch, pageNumber, sortBy]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  // Reset to first page when search value changes
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const updateSortExpression = (field: string, expression: string) => {
    setSortBy(expression);
    setPageNumber(1);
  };

  const [lastElementRef] = useInfiniteScroll(hasNextPage ? () => setPageNumber((prev) => prev + 1) : null, loading);

  const createTenant = () => {
    AdminService.createTenant({
      name: newAccountName,
      type: selectedAccountType?.value,
      assignCurrentUser: false,
      tenantId: null,
      language: systemDefaultLanguageCode,
    }).then((res) => {
      toastsRef.current.addToast({
        type: ToastType.INFO,
        title: t('accounts:create-tenant-toaster.creating.heading'),
        description: t('accounts:create-tenant-toaster.creating.description'),
      });
      listenToProcess<Client>(res.data)
        .then((tenant) => {
          toastsRef.current.addToast({
            type: ToastType.SUCCESS,
            title: t('accounts:create-tenant-toaster.created.heading'),
            description: t('accounts:create-tenant-toaster.created.description'),
            slots: {
              button: (
                <Button type={ButtonType.TERTIARY} className="border-2 border-b" onClick={() => navigate(`/admin/iam/organisations/${tenant.id}`)}>
                  {t('accounts:create-tenant-toaster.created.view-button')}
                </Button>
              ),
            },
          });
        })
        .catch(() => {
          toastsRef.current.addToast({
            type: ToastType.ERROR,
            title: t('accounts:create-tenant-toaster.fail.heading'),
            description: t('accounts:create-tenant-toaster.fail.description'),
          });
        });
      setNewTenantModalOpen(false);
    });
  };

  const onAccountDeleteClick = () => {
    if (accountToDelete) {
      ClientService.deleteClient(accountToDelete.id).then(() => {
        setShowDeleteConfirmModal(false);
        setAccountToDelete(null);
        setAccounts((prev) => prev.filter((account) => account.id !== accountToDelete.id));
      });
    }
  };

  useEffect(() => {
    setIsValid(newAccountName.length > 0 && accountTypeOptions.map((x) => x.value).includes(newAccountType));
  }, [accountTypeOptions, newAccountName.length, newAccountType]);

  return (
    <div className="bg-background-1 flex min-h-full flex-col ">
      <div className="flex h-full flex-col pt-6">
        <div className="flex items-center justify-between">
          <TopNavPortal>
            <Heading size={HeadingSize.H3}>{t('accounts:heading')}</Heading>
          </TopNavPortal>
          <div className="flex w-full justify-between p-4">
            <div className="w-80">
              <SearchInput
                data-cy="account-search"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                placeholder={t('accounts:filter-placeholder')}
              />
            </div>
            <Button data-cy="show-new-tenant-modal" type={ButtonType.PRIMARY} size={ButtonSize.M} onClick={() => setNewTenantModalOpen(true)}>
              {t('accounts:create-tenant-button')}
            </Button>
          </div>
        </div>

        <div className="flex flex-1 flex-col p-4">
          <div className="flex items-end border-l-2 border-transparent">
            <div className="w-3/6">
              <SortableHeading title={t('accounts:headings.name')} onSort={updateSortExpression} expression="+name" />
            </div>
            <div className="w-3/6">{t('accounts:headings.type')}</div>
            <div className="w-10">{/* SPACER */}</div>
            <div className="w-10">{/* SPACER */}</div>
          </div>

          {accounts.length ? (
            accounts.map((account, i) => {
              const isLast = accounts.length === i + 1;
              return (
                <div key={`${account.id}.${i}`} ref={isLast ? lastElementRef : undefined}>
                  <DataRow
                    data-cy={`account-${account.id}`}
                    title={`View ${account.name}`}
                    url={`/admin/iam/organisations/${account.id}`}
                    contextMenuItems={[
                      {
                        title: t('accounts:delete'),
                        onClick: () => {
                          setAccountToDelete(account);
                          setShowDeleteConfirmModal(true);
                        },
                      },
                    ]}
                  >
                    <div className="text-color-1 w-3/6 font-medium">
                      <div className="flex items-center">
                        {account.isTenant && <Building2Icon className="mr-2 h-5 w-5" />} {account.name}
                      </div>
                    </div>
                    <div className="text-color-1 w-3/6 font-medium">
                      {t(`accounts:account-types.${account.type || AccountType.Organisation}`)}
                      {account.isTenant && <span className="text-dpm-14 ml-2 italic">({t('common:tenant')})</span>}
                    </div>
                  </DataRow>
                </div>
              );
            })
          ) : (
            <div data-cy="accounts-empty" className="text-dpm-20 text-color-1 flex min-h-32 w-full items-center justify-center font-medium">
              {t('accounts:no-accounts')}
            </div>
          )}
          {loading && (
            <div className="flex flex-col items-center py-6">
              <Loader size={16} centered={false} />
            </div>
          )}
        </div>
        <ModalContext.Provider value={{ open: newTenantModalOpen, onClose: () => setNewTenantModalOpen(false), modalWidth: 'w-1/4' }}>
          <StandardModal
            title={t('accounts:new-tenant-modal.heading')}
            onCancelClick={() => setNewTenantModalOpen(false)}
            confirmButtonTitle={t('accounts:new-tenant-modal.create-button')}
            onConfirmClick={createTenant}
            confirmDisabled={!isValid}
          >
            <Input
              data-cy="tenant-name"
              value={newAccountName}
              onChange={(e) => setNewAccountName(e.target.value)}
              placeholder={t('accounts:new-tenant-modal.name-placeholder')}
              label={t('accounts:new-tenant-modal.name-placeholder')}
            />
            <DropdownSelect
              data-cy="account-type"
              className="mt-8"
              placeholder={t('accounts:new-account-modal.type-placeholder')}
              options={accountTypeOptions}
              onChange={(o) => {
                setNewAccountType(o.value as AccountType);
              }}
              value={selectedAccountType}
            />
          </StandardModal>
        </ModalContext.Provider>

        <ModalContext.Provider value={{ open: showDeleteConfirmModal }}>
          <ConfirmationModal
            title={t(`accounts:delete-account-modal.title`)}
            description={t(`accounts:delete-account-modal.description`)}
            confirmText={t(`accounts:delete-account-modal.confirm-text`)}
            onCancel={() => setShowDeleteConfirmModal(false)}
            onConfirm={onAccountDeleteClick}
            alt
          />
        </ModalContext.Provider>
      </div>
    </div>
  );
};

export default Organisations;
